
// _base.scss imports _custom.scss and other partials.
@import "base";

/*
 * @file
 * Print Styles
 * This probably needs work, but it's a good starting point.
 */

/*
 * Body
 */
body {
  zoom:1;
  width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 1.4;
  word-spacing: 1.1pt;
  letter-spacing: 0.2pt;
  font-family: Verdana, Geneva, Arial, Helvetica, sans-serif;
  color: rgb(0, 0, 0);
  background: none;
  font-size: 10pt;
}


/*
 * Links
 */
a {
  background: transparent;
  font-weight: normal;
  text-decoration: underline;
  color: $text_color;
}

/*
 * Strip background from likely candidates
 */
div[id*="wrapper"],
[class*="title"],
[class*="inner"],
header,
footer,
#branding,
#container,
#columns,
.columns-inner,
#content-column,
.content-inner,
.region,
.region-header,
.region-content,
.article,
.article-title,
.article-content,
.comment,
.comment-title,
.comment-content,
.block,
.block-inner,
.block-title,
.block-content,
table,
table * {
  background: none !important;
}


/*
 * Hide various bits of the page
 */
.sidebar,
#breadcrumb,
.nav,
.field-type-taxonomy-term-reference,
ul.links,
.feed-icon,
.poll .bar,
.poll .foreground,
.comment-form,
#comment-form,
.book-navigation,
.tabs,
.action-links,
.pager,
.messages,
.help,
#admin-menu,
#navigation{
  display: none;
}

/* Make Form Fields printable */
label,
.form-text{
  display:block;
  clear:both;
}


/*
 * Ensure the display the main content region
 */
#content {
  display: block !important;
}


/*
 * Layout
 */
#header {
  margin-bottom: 20px;
}


.content-inner {
  margin: 0 !important;
  padding: 0 !important;
}


/*
 * HTML elements
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text_color;
  margin: 0;
  padding: 0;
  line-height: 1;
}


h1 {
  font-size: 14pt;
}


h2 {
  font-size: 13pt;
}


h3 {
  font-size: 12pt;
}


h4,
h5,
h6 {
  font-size: 11pt;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
#site-name a,
#site-slogan {
  text-decoration: none !important;
  color: $text_color;
}


hgroup a {
  &:link,
  &:visited {
    text-decoration: none;
  }
}


#page-title {
  margin-top: 20px;
}


pre,
code,
tt,
samp,
kbd,
var {
  font-size: 10pt;
  font-family: Consolas, "Lucida Console", Menlo, Monaco, "DejaVu Sans Mono", monospace, sans-serif;
}


blockquote {
  margin: 20px;
  padding: 10px;
  font-size: 10pt;
  font-style: italic;
  background: $text_color;
}


hr {
  background-color: $grey_dark;
}


/*
 * Header, Logo, Site name
 */
#header {
  padding-bottom: 20px;
}


#logo img {
  padding: 0 10px 0 0;
  margin: 0 !important;
  float: none !important;
}


/*
 * Images
 */
img {
  float: left;
  margin: 4px 20px 10px 0;
  page-break-inside: avoid;
}


a img {
  border: none;
 }


/*
 * Tables
 */
table {
  margin: 1px;
  text-align: left;
}


th {
  border-bottom: 1px solid $grey;
  font-weight: 700;
}


td {
  border-bottom: 1px solid $grey;
}


th,
td {
  padding: 4px 10px 4px 0;
}


tfoot {
  font-style: italic;
}


caption {
  margin-bottom: 10px;
  text-align: left;
}


thead {
  display: table-header-group;
}


tr {
  page-break-inside: avoid;
}


/*
 * Forms
 */
form {
  margin-bottom: 10px; }


/* Views */
.views-row{
  clear:both;
}

/*
 * Poll module
 */
.poll {
  .text,
  .percent,
  .total {
    text-align: left;
  }
  form {
    margin-bottom: 0;
  }
}


/*
 * Articles
 */
.node,
.comment {
  margin-bottom: 20px;
  overflow: hidden;
  page-break-inside: avoid;
}

.submitted {
  color: rgb(102, 102, 102);
  text-decoration: none;
  font-size: 9pt;
  font-weight: 400;
  margin: 0;
  padding: 0;

  a {
    color: rgb(102, 102, 102);
    text-decoration: none;
    font-size: 9pt;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
}
